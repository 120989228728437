@import '../../../../../assets/scss/theme3.module';
.main-box {
    width: 730px;
    max-width: 730px;
    overflow-x: hidden !important;
}
.type-box {
    border: none;
    overflow-x: hidden !important;
    overflow-x: hidden !important;
    min-width: 220px;
    max-height: 335px !important;
    border-radius: 6px !important;
    color: $darkTextTitle;
    background: $paper !important;

    .type-box-content {
        overflow-x: hidden !important;
        overflow-x: hidden !important;
        height: 300px !important;
        .disabled > * {
            color: $grey400 !important;
        }
    }
    .type-box-display {
        overflow-x: hidden !important;
        overflow-x: hidden !important;
        height: 130px !important;
    }
    .type-icon {
        font-size: 95px !important;
        color: $grey700;
        &:hover {
            color: $secondaryMain;
        }
    }
    .text {
        margin-top: -10px;
        color: $grey700;
        &.sub {
            margin-top: 10px;
            text-align: center;
            font-size: 16px;
            color: $grey600;
            margin-bottom: 40px;
            display: block !important;
            &.disabled {
                color: $grey400 !important;
            }
        }
        &.disabled {
            color: $grey400 !important;
        }
    }
    padding-bottom: 35px;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        cursor: pointer;
        .text {
            color: $secondaryMain !important;
        }
        .type-icon {
            color: $secondaryMain !important;
        }
        .disabled {
            color: $grey400 !important;
        }
    }
    &.selected {
        .text {
            color: $secondaryMain !important;
            font-weight: bold;
        }
        .type-icon {
            color: $secondaryMain !important;
        }
    }
}
.action-divider {
    margin-bottom: 0px;
}
.action-footer {
    margin-top: 10px;
    button {
        margin-right: 10px;
    }
}
.participants-box {
    border: 1px solid #cad9d9;
    border-radius: 4px;
}
.main {
    background-color: white;
    overflow-x: hidden !important;
    h1 {
        color: $grey700 !important;
        margin-top: 5px;
        margin-left: 25px;
        font-size: 32px;
    }
}
.create-box-content {
    border-radius: 4px;
    overflow-y: hidden;
    overflow-x: hidden !important;
}
.schedule-note {
    // margin-left: 5px;
    margin-top: 0px;
}
