@import './theme3.module';

.logo-dark {
    .macrosectiontext {
        text-decoration: none !important;
    }
    margin-left: 25px;
}
.logo-light {
    text-decoration: none !important;
    margin-left: 25px;
}
.logo__link {
    text-decoration: none !important;
}
.cc-logo {
    font-size: 30px;
    text-align: center;
    color: orange;
    font-family: system-ui;
    vertical-align: text-bottom;
}
.cc-avatar-box {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    text-align: center;
    .placeholder {
        background-color: transparent;
    }
    .avatar-edit {
        color: white !important;
        // border-radius: 50%;
    }
}
div[disabled] {
    pointer-events: none;
    opacity: 0.7;
}
.preview-media-holder {
    .img {
        border-radius: 5px !important;
        width: min-content;
    }
    img {
        border-radius: 5px;
    }
}
.cc-drop-box {
    opacity: 0.8;
    border-radius: 8px;
    border: 2px dashed rgb(145, 145, 145);
    .placeholder {
        cursor: pointer;
        height: 100%;
        p {
            font-size: 16px !important;
            a {
                font-size: 16px !important;
            }
        }

        .icon {
            padding-top: 5px !important;
            // padding-top: 10px;
            font-size: 52px !important;
        }
    }
    .placeholder-empty {
        cursor: not-allowed;
        opacity: 0.5;
        // padding-top: 100px;
        height: 100%;
        width: 100%;
        font-size: 45px;
        font-weight: lighter;
        p {
            margin-top: 5px;
            font-size: 14px !important;
            text-align: center;
            // width: 100%;
            width: 180px;
        }

        .icon {
            padding-top: 5px !important;
            // padding-top: 10px;
            font-size: 52px !important;
        }
    }
}

.date-box {
    width: 54px;
    height: 54px;
    background-color: $darkPrimaryMain;
    text-align: center;
    padding-top: 7px;
    color: white;
    border-radius: 5px;
    .month {
        font-weight: 500;
        font-size: 14px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
    .days {
        font-weight: bold;
        font-size: 18px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
}
.date-box-title {
    font-size: 12px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.memberTotal {
    margin-top: 4px;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.334em;
    font-family: 'Roboto', sans-serif;
    color: #616161;
}
