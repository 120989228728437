@import '../../../../assets/scss/_theme3.module.scss';
.page-title {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    margin-left: 6px;
    margin-bottom: 8px;
}
.date-box {
    width: 54px;
    height: 54px;
    background-color: $darkPrimaryMain;
    text-align: center;
    padding-top: 7px;
    color: white;
    border-radius: 5px;
    .month {
        font-weight: 500;
        font-size: 14px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
    .days {
        font-weight: bold;
        font-size: 18px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
}
.date-box-title {
    font-size: 12px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.event-text {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.334em;
    font-family: 'Roboto', sans-serif;
    color: #616161;

    .event-icon {
        min-width: 56px;
        color: rgba(0, 0, 0, 0.54);
        flex-shrink: 0;
        display: inline-flex;
        color: #616161;
        min-width: 36px;
        margin-top: auto;
        margin-bottom: auto;
        min-width: 36px;
    }
}
.text-count {
    font-size: 12px;
    padding-left: 2px;
}
.justified {
    text-align: justify;
    padding-right: 10px;
}
.event-subtext-bold {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.334em;
    font-family: 'Roboto', sans-serif;
    color: #616161;
}
.event-subtext {
    margin: 0;
    font-size: 16px;
    line-height: 1.334em;
    font-family: 'Roboto', sans-serif;
    color: #616161;
}
.event-subtitle {
    margin: 0;
    font-size: 14px;
    line-height: 1.334em;
    font-family: 'Roboto', sans-serif;
    color: #616161;
}
.event-subtext2 {
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.334em;
    font-family: 'Roboto', sans-serif;
    color: #616161;
}

.countdown-box {
    margin-top: 5px !important;
    padding: 10px 5px 5px 5px;
    // border: 1px dashed grey;
    font-family: 'Roboto', sans-serif;
    display: inline-block;
    width: 55px;
    font-size: 20px;
    text-align: center;
    .title-box {
        width: 45px;
        text-align: center;
        font-size: 12px;
        display: inline-block;
    }
}

.schedule {
    margin-top: 15px;
    margin-bottom: 10px;
}
.toolbox {
    position: relative;
    align-items: flex-end;
    color: #616161 !important;
}
.actionbox {
    position: relative;
    align-items: flex-start;
    color: #616161 !important;
    button {
        color: $darkPrimaryMain;
    }
}
.countdown-widget {
    // font-size: 12px !important;
    text-align: left !important;

    .countdown-box {
        margin-top: 10px !important;
        padding: 0px 0px 0px 0px;
        // border: 1px solid black;
        margin-right: 5px;
        width: 30px;
        // border: 1px dashed grey;
        font-family: 'Roboto', sans-serif;
        display: inline-block;
        // width: 55px;
        font-size: 20px;
        text-align: left;
        .title-box {
            width: 35px !important;
            text-align: left;
            font-size: 8px;
            display: none !important;
            display: inline-block;
        }
    }
    .countdown-box:last-child {
        &:after {
            content: 's' !important;
            font-size: 10px !important;
            font-weight: bold;
        }
    }
    .countdown-box:nth-child(1) {
        &:after {
            content: 'd' !important;
            font-size: 10px !important;
            font-weight: bold;
        }
    }
    .countdown-box:nth-child(2) {
        &:after {
            content: 'h' !important;
            font-size: 10px !important;
            font-weight: bold;
        }
    }
    .countdown-box:nth-child(3) {
        &:after {
            content: 'm' !important;
            font-size: 10px !important;
            font-weight: bold;
        }
    }
}
.left-panel {
    display: block;
    // min-height: 100vh !important;
}
